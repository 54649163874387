import React from "react"
import { graphql } from "gatsby"
import Contact from "../sections/contact/contact"
import Layout from "../components/global/layout"
import Footer from "../components/footer/footer"
import Section from "../components/global/section"
import BlogPosts from "../sections/blog/blog-posts"

const Blog = ({
  location,
  data: {
    datoCmsHomeSeo,
    datoCmsSite,
    footer,
    allPosts,
  },
}) => {
    return (
    <>
      <Layout
        seo={datoCmsHomeSeo.seoMetaTags}
        favicon={datoCmsSite.faviconMetaTags}
        location={location}
      >
        <Section gradientAlt id={"blog"}>
          <BlogPosts allPosts={allPosts} />
        </Section>
        <Section gradient id={"contact"}>
          <Contact />
          <Footer {...footer} />
        </Section>
      </Layout>
    </>
  )
}
export default Blog

// This is the query that does all the magic
// Need to search the graphql layer? http://localhost:8000/___graphql
export const query = graphql`
  {
    datoCmsHomeSeo {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    datoCmsSite {
      faviconMetaTags {
        tags
      }
    }
    allPosts: allDatoCmsPost(sort: { fields: date, order: DESC }, limit: 20) {
      nodes {
        id
        title
        slug
        excerpt
        date
        coverImage {
          large: gatsbyImageData(width: 2000)
          small: gatsbyImageData(aspectRatio: 1.7, width: 760)
        }
        author {
          name
          picture {
            gatsbyImageData(
              layout: FIXED
              width: 48
              height: 48
              imgixParams: { sat: -100 }
            )
          }
        }
      }
    }
    footer: datoCmsFooterSection {
      heading
      links {
        ...Link
      }
      address
      credit
      email
      linkedin
      url
      urlText
      privacy {
        url
        title
      }
      terms {
        url
        title
      }
    }
  }
`
